import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Section, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaBalanceScale, FaBook, FaBookOpen, FaCoins, FaUserTie } from "react-icons/fa";
import { MdAccessibility } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Bos Balance: Financial Expert for Expats & Freelancers in the Netherlands
			</title>
			<meta name={"description"} content={"Tailored financial and tax services for expats and freelancers in the Netherlands. Expert support in bookkeeping, VAT, and income tax by a qualified Chartered Accountant. Achieve financial clarity with Bos Balance."} />
			<meta property={"og:title"} content={"Bos Balance: Financial Expert for Expats & Freelancers in Netherlands"} />
			<meta property={"og:description"} content={"Tailored financial and tax services for expats and freelancers in the Netherlands. Expert support in bookkeeping, VAT, and income tax by a qualified Chartered Accountant. Achieve financial clarity with Bos Balance."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
		</Helmet>
		<Components.TopNavBar>
			<Override slot="link" />
			<Override slot="quarklycommunityKitMenu" />
			<Override slot="text" />
			<Override slot="quarklycommunityKitMenuOverride" />
			<Override slot="Cookie Consent" />
		</Components.TopNavBar>
		<Section padding="88px 0 88px 0" background="radial-gradient(50% 50% at 52.09% 50%,rgba(0, 0, 0, 0.3) 67.1%,rgba(0, 0, 0, 0.4) 100%),url(https://images.unsplash.com/photo-1536921794304-9448f5bf861e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) center center/cover repeat scroll padding-box" quarkly-title="Hero-25">
			<Override
				slot="SectionContent"
				lg-justify-content="center"
				flex-direction="row"
				flex-wrap="wrap"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="repeat(1, 1fr)"
			/>
			<Box
				lg-margin="0px 0px 40px 0px"
				display="flex"
				align-items="flex-start"
				flex-direction="column"
				justify-content="space-between"
				lg-width="100%"
			>
				<Box
					width="80%"
					flex-direction="column"
					align-items="flex-start"
					lg-width="100%"
					lg-align-items="center"
					sm-margin="0px 0px 40px 0px"
					sm-padding="0px 0px 0px 0px"
					display="flex"
					justify-content="center"
					lg-margin="0px 0px 40px 0px"
					md-align-items="flex-start"
				>
					<Text
						margin="0px 0px 16px 0px"
						color="--lightD2"
						font="--base"
						lg-text-align="center"
						letter-spacing="1px"
						text-transform="uppercase"
						as="h1"
					>
						bookkeeping services
					</Text>
					<Text
						margin="0px 0px 36px 0px"
						color="--light"
						font="--headline1"
						lg-text-align="center"
						sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
						md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Welcome to Bos Balance
					</Text>
					<Text
						lg-text-align="center"
						lg-width="80%"
						width="70%"
						sm-width="100%"
						margin="0px 0px 0px 0px"
						color="--lightD2"
						font="normal 600 28px/1.5 --fontFamily-sans"
						md-text-align="left"
					>
						Your Personal Financial Guide Awaits
					</Text>
				</Box>
				<Box
					width="90%"
					justify-content="space-between"
					lg-width="100%"
					sm-flex-direction="column"
					display="grid"
					md-flex-direction="column"
					grid-template-columns="repeat(3, 1fr)"
					sm-grid-template-columns="repeat(1, 1fr)"
					sm-justify-items="start"
					align-items="start"
				>
					<Box
						align-items="flex-start"
						border-radius="50px"
						lg-max-width="none"
						padding="12px 12px 12px 12px"
						flex-direction="column"
						display="flex"
						justify-content="flex-start"
						sm-justify-content="center"
						sm-align-items="center"
						md-flex-direction="row"
						md-align-items="center"
						md-display="flex"
						md-grid-gap="18px"
					>
						<Icon category="fa" icon={FaBalanceScale} color="--light" size="32px" />
						<Text font="--base" color="--light" margin="15px 0px 0px 0px" md-margin="0px 0px 0px 0px">
							Personalised financial and tax services in English
						</Text>
					</Box>
					<Box
						flex-direction="column"
						sm-justify-content="center"
						sm-align-items="center"
						border-radius="50px"
						padding="12px 12px 12px 12px"
						display="flex"
						justify-content="flex-start"
						align-items="flex-start"
						lg-max-width="none"
						md-flex-direction="row"
						md-align-items="center"
						md-display="flex"
						md-grid-gap="18px"
					>
						<Icon category="fa" icon={FaBook} color="--light" size="32px" />
						<Text margin="15px 0px 0px 0px" font="--base" color="--light" md-margin="0px 0px 0px 0px">
							Your personal guide through the complexities of finance in the Netherlands
						</Text>
					</Box>
					<Box
						padding="12px 12px 12px 12px"
						flex-direction="column"
						justify-content="flex-start"
						sm-justify-content="center"
						sm-align-items="center"
						border-radius="50px"
						lg-max-width="none"
						display="flex"
						align-items="flex-start"
						md-flex-direction="row"
						md-align-items="center"
						md-display="flex"
						md-grid-gap="18px"
					>
						<Icon category="md" icon={MdAccessibility} color="--light" size="32px" />
						<Text margin="15px 0px 0px 0px" font="--base" color="--light" md-margin="0px 0px 0px 0px">
							Free yourself from the stress of financial reporting
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				lg-margin="20px 0px 0px 0px"
				padding="36px 36px 48px 36px"
				background="--color-darkL2"
				md-padding="28px 20px 28px 20px"
				sm-padding="28px 30px 28px 30px"
				lg-width="100%"
			>
				<Components.EmailForm>
					<Override slot="HeadingText" />
				</Components.EmailForm>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 0px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-margin="0px 0px 30px 0px"
				sm-align-items="center"
			>
				<Image
					max-width="340px"
					src="https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/treescale.webp?v=2024-02-04T13:16:40.052Z"
					md-margin="0px 0px 20px 0px"
					srcSet="https://smartuploads.quarkly.io/65bf71cb867c86001f1b6705/images/treescale.webp?v=2024-02-04T13%3A16%3A40.052Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65bf71cb867c86001f1b6705/images/treescale.webp?v=2024-02-04T13%3A16%3A40.052Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65bf71cb867c86001f1b6705/images/treescale.webp?v=2024-02-04T13%3A16%3A40.052Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65bf71cb867c86001f1b6705/images/treescale.webp?v=2024-02-04T13%3A16%3A40.052Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65bf71cb867c86001f1b6705/images/treescale.webp?v=2024-02-04T13%3A16%3A40.052Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65bf71cb867c86001f1b6705/images/treescale.webp?v=2024-02-04T13%3A16%3A40.052Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65bf71cb867c86001f1b6705/images/treescale.webp?v=2024-02-04T13%3A16%3A40.052Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					padding="0px 0px 0px 55px"
					sm-padding="0px 0px 0px 0px"
				>
					Financial Clarity and Balance
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text
					margin="0px 0px 40px 0px"
					color="--dark"
					font="--headline2"
					md-margin="0px 0px 30px 0px"
					as="h2"
				>
					About Bos Balance
					<br />
					and Me, Blaise
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--lead">
					Your Partner in Financial Clarity and Balance
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base">
					Welcome to Bos Balance. I'm Blaise, a qualified Chartered Accountant and your personal guide through the
			complexities of finance in the Netherlands. After I completed my studies with Chartered Accountants Ireland, I
			moved to the Netherlands in 2019 and realised the unique financial challenges faced here, especially by expats and
			freelancers. At Bos Balance, I'm dedicated to offering personalised financial and tax services in English, helping
			you settle and succeed with ease.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="--color-light" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--primary"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					as="h2"
				>
					My Services
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--primary"
					md-text-align="center"
					text-align="center"
					max-width="480px"
				>
					Tailored Financial Solutions Just for You
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="fa"
						icon={FaBookOpen}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						as="h3"
					>
						Bookkeeping and Day-to-Day Accounting
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Let me simplify your financial management. From handling invoices to accurate account reconciliations, I take
				care of the details for you.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="fa"
						icon={FaCoins}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						as="h3"
					>
						Financial Management
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Need clarity on your financial status? I prepare detailed financial statements, manage VAT services, and ensure
				your annual accounts are spot-on, helping you make informed decisions.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="fa"
						icon={FaUserTie}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						as="h3"
					>
						Consulting Services for Expats and Freelancers
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Are you new to the Netherlands or managing freelance finances? I specialise in assisting with income tax
				returns, navigating the local tax system, and providing bespoke advice to fit your unique situation.{" "}
						<Link href="/freelancers">
							Read more here.
						</Link>
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--primary"
					md-text-align="center"
					text-align="center"
					max-width="480px"
				>
					At Bos Balance, it's not just about balancing books; it's about bringing balance to your life. Free yourself from
			the stress of financial reporting and focus on what you do best. Ready to start your journey towards financial
			peace of mind? Let's chat.
				</Text>
			</Box>
			<Box min-width="100px" min-height="100px" text-align="center">
				<Components.QuarklycommunityKitPopup>
					<Override slot="Wrapper" />
					<Override slot="Content" background="--color-primary" />
					<Override slot="Button Close" color="--light" />
					<Override
						slot="Button Open"
						href="#"
						color="--light"
						text-align="center"
						padding="25px 50px 25px 50px"
						font="--lead"
						background="--color-utOrange"
						hover-background="--color-selectiveYellow"
						text-decoration="none"
					>
						CONTACT ME
					</Override>
					<Box
						lg-margin="20px 0px 0px 0px"
						background="--color-darkL2"
						md-padding="28px 20px 28px 20px"
						sm-padding="28px 30px 28px 30px"
						lg-width="100%"
						padding="36px 36px 48px 36px"
					>
						<Components.EmailForm />
					</Box>
				</Components.QuarklycommunityKitPopup>
			</Box>
		</Section>
		<Components.Footer padding="80px 0 125px 0">
			<Override
				slot="Creator"
				margin="20px 0 0 0"
				justify-content="flex-start"
				align-self="flex-end"
				sm-align-self="center"
			/>
			<Override slot="box5" sm-text-align="center" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bf71cb867c86001f1b6703"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});